<template>
  <div>
    <v-container>
      <h2 class="text-h5 mb-4"> FAQ Contents &nbsp;&nbsp; (Total: {{ contents.total }})</h2>
      <v-card class="mb-4" style="padding: 20px">
        <v-row>
          <v-col md="3">
            <v-text-field
              v-model="filters.search_keyword"
              label="Search Keyword"
              outlined
              @change="fetchFaqContents()"
              dense
              placeholder="Search Keyword"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-select
              style="margin-right: 10px"
              v-model="filters.section_id"
              dense
              :items="sections.data"
              item-text="name"
              item-value="id"
              @change="fetchFaqContents()"
              label="Section"
              outlined
            >
            </v-select>
          </v-col>
           <v-col md="3">
            <v-select
              style="margin-right: 10px"
              v-model="filters.status"
              dense
              :items="[{name : 'All' , value : ''},{ name : 'Active', value : 1}, {name : 'In-Active' , value : 0}]"
              item-text="name"
              item-value="value"
              @change="fetchFaqContents()"
              label="Status"
              outlined
            >
            </v-select>
          </v-col>
          <v-col md="6" style="text-align: right;">
            <v-btn
          color="primary"
          @click="create_content_dialog = true"
          class="tet-white mr-2"
          small
          >Create New</v-btn
        >
          </v-col>
        </v-row>
      </v-card>
      <v-data-table :headers="headers" :items="contents.data" class="elevation-1" disable-pagination hide-default-footer>
        <template v-slot:body="{ items }">
          <tbody v-if="!loading_data">
            <tr v-for="item in items" :key="item.id">
              <td>{{ item.title }}</td>
              <td>
                <v-chip small class="ma-2" color="green" text-color="white" v-if="item.status == '1'"
                  >Active</v-chip
                >
                <v-chip small class="ma-2" color="red" text-color="white" v-else>Inactive</v-chip>
              </td>
              <td>{{ item.section ? item.section.name : "" }}</td>
              <td>
                <v-btn fab small color="primary darken-1" class="table-action--button" @click="openEditDialog(item.id)">
                <v-icon>{{ mdiSquareEditOutline }}</v-icon>
              </v-btn>

              <v-btn fab small color="primary darken-1" class="table-action--button" @click="openDeleteDialog(item.id)">
                <v-icon>{{ mdiTrashCanOutline }}</v-icon>
              </v-btn>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td colspan="7" class="text-center" style="padding: 80px 0px">
                <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-pagination
        v-model="contents.current_page"
        class="my-4"
        @input="handlePageChange"
        :length="contents.last_page"
        :total-visible="7"
      ></v-pagination>
    </v-container>


    <!-- CREATE DIALOG BOX -->
    <v-dialog v-model="create_content_dialog" width="1000" :retain-focus="false" >
      <v-card>
        <v-card-title class="text-h6 grey lighten-2"> Create New FAQ Content </v-card-title>

        <v-card-text>
          <div style="padding: 20px">
              <v-text-field
                type="text"
                v-model="new_content.title"
                class="w-full"
                label="Content Name"
                outlined
              ></v-text-field>
              <v-text-field
                type="number"
                v-model="new_content.position"
                class="w-full"
                label="Content Position"
                outlined
              ></v-text-field>
             <v-select
              v-model="new_content.status"
              dense
              :items="content_status"
              label="Status"
              item-text="name"
              item-value="value"
              outlined></v-select>
              <br>
              <v-select
              v-model="new_content.section_id"
              dense
              :items="sections.data"
              label="Section"
              item-text="name"
              item-value="id"
              outlined></v-select>
          </div>
          <div style="padding: 20px;">
             <ckeditor :editor="editor" v-model="new_content.description" :config="editorConfig"></ckeditor>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="create_content_dialog = false"> CANCEL </v-btn>
          <v-btn color="primary" text @click="createContent" :loading="is_content_create_clicked" :disabled="is_content_create_clicked">
            <v-progress-circular v-if="is_content_create_clicked" indeterminate color="primary" size="20"></v-progress-circular>
            <span v-else>Done</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- EDIT DIALOG BOX -->
    <v-dialog v-model="edit_content_dialog" width="1000" :retain-focus="false" >
      <v-card>
        <v-card-title class="text-h6 grey lighten-2"> Edit FAQ Content </v-card-title>

        <v-card-text>
          <div style="padding: 20px">
              <v-text-field
                type="text"
                v-model="edit_content.title"
                class="w-full"
                label="Content Title"
                value="edit_content.title"
                outlined
              ></v-text-field>
              <v-text-field
                type="number"
                v-model="edit_content.position"
                class="w-full"
                label="Content Position"
                value="edit_content.position"
                outlined
              ></v-text-field>
             <v-select
                v-model="edit_content.status"
                dense
                :items="content_status"
                label="Status"
                item-text="name"
                item-value="value"
                outlined></v-select>
                <br>
              <v-select
                v-model="edit_content.section_id"
                dense
                :items="sections.data"
                label="Section"
                item-text="name"
                item-value="id"
                outlined></v-select>
          </div>
           <div style="padding: 20px;">
             <ckeditor :editor="editor" v-model="edit_content.description" :config="editorConfig"></ckeditor>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="edit_content_dialog = false"> CANCEL </v-btn>
          <v-btn color="primary" text @click="updateContent(edit_content.id)" :loading="is_content_create_clicked" :disabled="is_content_create_clicked">
            <v-progress-circular v-if="is_content_create_clicked" indeterminate color="primary" size="20"></v-progress-circular>
            <span v-else>Done</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- DELETE CONFIRMATION DIALOG BOX -->
    <v-dialog v-model="delete_content_dialog" width="500">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2"> Delete FAQ Content </v-card-title>

        <v-card-text>
          Are you sure you want to delete  {{ delete_content.name }}  content?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="delete_content_dialog = false"> CANCEL </v-btn>
          <v-btn color="primary" text @click="deleteContent(delete_content.id)" :loading="is_content_create_clicked" :disabled="is_content_create_clicked">
            <v-progress-circular v-if="is_content_create_clicked" indeterminate color="primary" size="20"></v-progress-circular>
            <span v-else>Confirm Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiLogin, mdiLoading, mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js'
import { mapActions } from "vuex";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  components: { },
  data() {
    return {
      editor : null,
      loading_data: false,
      is_first: true,
      filters: {
        search_keyword: '',
        status: '',
        section_id: 0
      },
      headers: [
        {
          text: 'Title',
          value: 'title',
        },
        { text: 'Status', value: 'status' },
        { text: 'Section', value: 'section' },
        { text: 'Actions', value: 'actions' },
      ],
      contents: {
        current_page: 1,
        data: [],
      },
      new_content : {
        title : '',
        status : 0,
        section_id : 0,
        description : '',
        position : 0
      },
      edit_content : {
        title : '',
        status : 0,
        section_id : 0,
        description : '',
        position : 0
      },
      delete_content : {
        title : '',
        status : 0,
        section_id : 0,
        description : '',
        position : 0
      },
      create_content_dialog: false,
      content_status : [{name: 'Active', value : 1}, {name : 'In-Active' , value : 0}],
      sections : {
        data: [],
        current_page: 1
      },
      //icons
      mdiLogin: mdiLogin,
      mdiLoading: mdiLoading,
      mdiSquareEditOutline: mdiSquareEditOutline,
      mdiTrashCanOutline: mdiTrashCanOutline,
      is_content_create_clicked: false,
      edit_content_dialog: false,
      delete_content_dialog: false,
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
          // The configuration of the editor.
      }
    }
  },
  mounted() {
    this.fetchFaqContents()
    this.fetchFaqSections()
  },

  methods: {
    ...mapActions(["LoginAsClient"]),
    handlePageChange(value) {
      this.fetchFaqContents()
    },
    async fetchFaqContents() {
        this.loading_data = true
        await axios.get('admin/faq/contents', {
          params: {
            page: this.contents.current_page,
            search: this.filters.search_keyword,
            status: this.filters.status,
            section_id: this.filters.section_id
          },
        }).then((res) => {
          this.contents = res.data
        }).catch((err) => {
          console.log(err);
        })
        this.loading_data = false

    },

    async fetchFaqSections() {
        await axios.get('admin/faq/sections', {
          params: {
            page: this.sections.current_page,
            search: this.filters.search_keyword,
            status: 1
          },
        }).then((res) => {
          this.sections = res.data
        }).catch((err) => {
          console.log(err);
        })
    },

    async createContent() {
        this.loading_data = true
        await axios.post('admin/faq/content', {
           title : this.new_content.title,
           status : this.new_content.status,
           section_id : this.new_content.section_id,
           description : this.new_content.description,
           position : this.new_content.position
        }).then((res) => {
            this.fetchFaqContents();
            this.create_content_dialog = false;
        }).catch((err) => {
          console.log(err);
        });
        this.new_content =   {
                title : '',
                status : 0,
                section_id : 0,
                description : '',
                position : 0
              }
        this.loading_data = false

    },


  async deleteContent(faq) {
        this.loading_data = true
    await axios.delete('admin/faq/content/' + faq)
          .then((res) => {
            this.fetchFaqContents();
            this.delete_content_dialog = false;
          }).catch((err) => {
            console.log(err);
          });
        this.loading_data = true

  },

  async updateContent(faq) {
        this.loading_data = true
        await axios.put('admin/faq/content/' + faq, {
           title : this.edit_content.title,
           status : this.edit_content.status,
           section_id : this.edit_content.section_id,
           description : this.edit_content.description,
           position : this.edit_content.position
        })
        .then((res) => {
          this.fetchFaqContents();
          this.edit_content_dialog = false
        }).catch((err) => {
          console.log(err);
        this.loading_data = false
        })

  },

  openEditDialog(faq_id) {
      var faq = this.contents.data.find(item => item.id === faq_id);
      console.log(faq);
      this.edit_content.id = faq.id;
      this.edit_content.title = faq.title;
      this.edit_content.status = faq.status;
      this.edit_content.section_id = faq.section_id;
      this.edit_content.description = faq.description;
      this.edit_content.position = faq.position;
      this.edit_content_dialog = true;
  },

  openDeleteDialog(faq_id) {
      var faq = this.contents.data.find(item => item.id === faq_id);
      this.delete_content.id = faq.id;
      this.delete_content.title = faq.title;
      this.delete_content.status = faq.status;
      this.delete_content.section_id = faq.section_id;
      this.delete_content.description = faq.description;
      this.delete_content.position = faq.position;
      this.delete_content_dialog = true;
  }
  },

}
</script>
